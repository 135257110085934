<template>
  <div :class="['tag', active ? 'tag_active' : '']">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "Tags",
  mixins: [],
  props: {
    text: {
      type: String,
      default: "Default Tag"
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    activeTag() {
      this.active = !this.active;
      this.$emit("update:tag", this.active);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.tag {
  width: max-content;
  padding: 4px;

  font-size: 12px;
  line-height: 13px;
  font-weight: 400;
  color: $color-interactive;

  border: 1px solid $color-interactive;
  border-radius: 3px;
  transition: all 0.3s ease;

  &_active {
    color: $color-background;
    background-color: $color-interactive;
  }
}
</style>
